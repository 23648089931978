import DownloadIcon from '@mui/icons-material/Download'
import { Box, Typography } from '@mui/material'
import { ReactComponent as ViewRatesIcon } from 'assets/icons/ViewRates.svg'
import {
  BarChart,
  DatePicker,
  MButton,
  YearDropDown,
  YearMonthPicker
} from 'components'
import { MONTHS, RATES_LINK, USER_TOKEN } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context/auth'
import dayjs from 'dayjs'
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { useLocalStorage } from 'utils/hooks'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import * as XLSX from 'xlsx'

const valueFormatter = (value: number | string | null): string => {
  return `${value} m³`
}

const renderDateFilter = (
  viewType: 'monthly' | 'daily' | 'hourly',
  value: string,
  onChange: any,
  options?: string[]
): JSX.Element => {
  switch (viewType) {
    case 'monthly':
      return (
        <YearDropDown
          value={value}
          onChange={onChange}
          yearOptions={options ?? ['2025', '2024']}
        />
      )
    case 'daily':
      return <YearMonthPicker value={value} onChange={onChange} />
    case 'hourly':
      return <DatePicker value={value} onChange={onChange} />
  }
}

const getViewTypeFilterValue = (
  viewType: 'monthly' | 'daily' | 'hourly',
  event: any
): string => {
  switch (viewType) {
    case 'monthly':
      return event.target.value
    case 'daily':
      return event.format('YYYY-MM')
    case 'hourly':
      return event.format('YYYY-MM-DD')
  }
}

interface IUsageViewGraph {
  viewType: 'monthly' | 'daily' | 'hourly'
  filters: Record<string, string>
  setFilters: Dispatch<SetStateAction<Record<string, string>>>
}

export const UsageViewGraph = ({
  viewType,
  filters,
  setFilters
}: IUsageViewGraph): JSX.Element => {
  const [seriesData, setSeriesData] = useState<number[] | null>(null)
  const [xAxisData, setXAxisData] = useState<string[]>(MONTHS)
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const { getUsage, premiseId, currentAccount, account, getAccount } =
    useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isMobile = useMobileBreakpoints()

  const handleDateFilterChange = (event: any): void => {
    setFilters({
      ...filters,
      [viewType]: getViewTypeFilterValue(viewType, event)
    })
  }

  const handleViewRates = (): void => {
    window.open(RATES_LINK, '_blank')
  }

  useEffect(() => {
    if (
      prevAccountId === currentAccount?.accountId ||
      currentAccount?.accountId === account?.myAccount?.accountId
    ) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId
      })
    }
    setPrevAccountId(
      currentAccount?.accountId ?? account?.myAccount?.accountId ?? ''
    )
  }, [authToken, currentAccount])

  const handleExport = (): void => {
    const exportData = xAxisData.map((period, index) => ({
      Period: period,
      Value: seriesData?.[index]
    }))


    const worksheet = XLSX.utils.json_to_sheet(exportData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Monthly Data')

    XLSX.writeFile(workbook, 'MonthlyData.xlsx')
  }

  useEffect(() => {
    const fetchYearlyUsage = async (): Promise<void> => {
      try {
        setIsLoading(true)
        if (premiseId === '') return
        const usageValue = await getUsage({
          AccessToken: authToken,
          premiseId: currentAccount?.premiseId ?? premiseId,
          accountId: currentAccount?.accountId ?? account?.myAccount?.accountId,
          frequency: 'M',
          startDate: `${filters.monthly}-01-01`,
          endDate: dayjs(`${filters.monthly}-01-01`)
            .add(1, 'year')
            .format('YYYY-MM-DD'),
          service: 'WATER'
        })

        if (((usageValue?.usage?.usageHistory) != null) && usageValue?.usage?.usageHistory.length !== 0) {
          const usageData = usageValue?.usage?.usageHistory?.[0]?.usageData

          const periods = usageData?.map(item => item.period) ?? []
          const values = usageData?.map(item => parseInt(item.value, 10)) ?? []

          setXAxisData(periods)
          setSeriesData(values)
        }
      } catch (error) {
        console.error('usage fetch: ', error)
      } finally {
        setIsLoading(false)
      }
    }
    void fetchYearlyUsage()
  }, [premiseId, currentAccount, authToken, filters])


  return (
    <>
      <Box p={3}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={1}
        >
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography fontSize={24} color={color.grey[900]} fontWeight={500}>
              Water Meter Consumption
            </Typography>
            <Typography fontSize={16} color={color.grey[700]} fontWeight={400}>
              Cubic Meter
            </Typography>
          </Box>

          <Box>
            {renderDateFilter(
              viewType,
              filters[viewType],
              handleDateFilterChange
            )}
          </Box>
        </Box>

        <BarChart
          seriesData={seriesData}
          xAxisData={xAxisData}
          valueFormatter={(value) => valueFormatter(value)} loading={isLoading} />

        <Box
          display="flex"
          alignItems="center"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={2}
        >
          <MButton
            variant="outlined"
            texttransform="none"
            rounded="true"
            size="large"
            startIcon={<DownloadIcon sx={{ color: color.primary[600] }} />}
            onClick={handleExport}
            fullWidth={isMobile}
          >
            Export to excel
          </MButton>


          <MButton
            variant="outlined"
            texttransform="none"
            rounded="true"
            size="large"
            startIcon={<ViewRatesIcon fill={color.primary[600]} />}
            onClick={handleViewRates}
            fullWidth={isMobile}
          >
            View Rates
          </MButton>
        </Box>

      </Box>

    </>
  )
}
